.quiz-page-landing-update {
  .quiz-landing-page-formatter {
    &__description {
      a.retake-quiz {
        width: 292px;
      }
    }
    &__results {
      p.quiz-landing-page-formatter {
        &__description-info {
          &--header,
          &--results,
          &--sub-header {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.account-section {
  &__content {
    .favorites-page {
      &__quiz-cta {
        a {
          padding: 11px 10px 8px 5px;
        }
      }
    }
  }
}

.trustmark_img {
  width: auto;
  text-align: center;
  margin-bottom: 20px;
}

.trustmark_logo {
  width: 100%;
  padding: 40px 15px;
}

.trustmark_img img {
  width: 44%;
}
