.legal_frame {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid $color-light-grey;
  padding: 10px;
  width: 100%;
}

.section-data-privacy-statement {
  .site-header__wrapper,
  .site-footer {
    display: none;
    &__sticky {
      display: none;
    }
  }
  .LPMcontainer,
  .optanon-alert-box-wrapper {
    display: none !important;
  }
  .site-content {
    padding-top: 0px !important;
  }
}

.form_element,
.form-item {
  .label {
    &.error {
      color: $red;
      a {
        color: $red;
        text-decoration: underline;
      }
    }
  }
}

.return-user {
  &__item {
    margin: 5px 0;
    .label {
      &.error {
        color: $red;
        a {
          color: $red;
          text-decoration: underline;
        }
      }
    }
    input[type='checkbox'] ~ .label {
      display: inline;
    }
  }
}

.checkout {
  .sign-in-page {
    .return-user,
    .new-account {
      margin-bottom: 10px;
      form {
        input {
          &.form-submit {
            position: relative;
            top: auto;
          }
        }
      }
      &__legal {
        font-size: 10px;
      }
    }
  }
  .checkout-order-terms {
    margin-top: 10px;
    .label {
      &.error {
        color: $red;
        a {
          color: $red;
          text-decoration: underline;
        }
      }
    }
  }
}

.signin-overlay {
  .signin-block {
    &__field-wrapper {
      .signin-block {
        &__checkbox {
          width: 90%;
        }
      }
    }
  }
}

#confirm {
  .content-container {
    .checkbox {
      margin: 5px 0;
      .label {
        width: 90%;
        a {
          margin-bottom: 0;
        }
      }
    }
  }
}

.profile-page {
  .sms-promotions {
    .label {
      line-height: 2.8em;
    }
  }
}
