.offer-block {
  background-color: #ecf1f1;
  padding: 10px;
  @include breakpoint($medium-up) {
    padding: 0;
  }
  .offer-block__description {
    width: 100%;
    text-align: center;
    margin: 0;
    @include breakpoint($medium-up) {
      font-size: 1.5em;
      width: 86%;
      padding: 1em;
      position: relative;
      z-index: 2;
      font-weight: bold;
    }
    p {
      margin: 0;
      @include breakpoint($medium-up) {
        line-height: 1;
        margin-bottom: 5px;
      }
      a {
        text-decoration: none;
        &:hover {
          @include breakpoint($medium-up) {
            color: $color-off-black;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .offer-block__image {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      width: auto;
      max-width: 100%;
      padding: 0;
      position: absolute;
      z-index: 1;
      height: 145px;
    }
  }
}

.welcome-15 {
  min-height: 340px;
  .site-email-signup {
    &__success {
      padding: 1.5em 0 0 2.5em;
      @include breakpoint($medium-down) {
        padding: 1em 0 0 1.5em;
      }
      &-offer {
        font-size: 20px;
        margin-top: 5px;
        @include breakpoint($medium-down) {
          font-size: 18px;
        }
      }
    }
    &__success-header {
      line-height: initial;
      font-size: 32px;
      @include breakpoint($medium-down) {
        font-size: 28px;
      }
    }
    &__terms-details {
      margin-top: 5px;
    }
    &__success-terms {
      width: 100%;
      text-align: $ldirection;
      .info-link-gdpr {
        margin-top: 12px;
        color: $white;
        line-height: initial;
      }
    }
    &__success-offer {
      padding-bottom: 10px;
    }
    &__success--welcome-15 {
      .welcome-15_site-email-signup {
        .email_input {
          .form-submit {
            margin-top: 5px;
            &:hover {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  &.success {
    padding: 30px 10px 0 10px;
    .site-email-signup {
      &__success-header,
      &__success-offer,
      &__success-reminder {
        width: auto;
      }
      &__success-header {
        padding-bottom: 10px;
      }
      &__success-reminder {
        margin-top: 10px;
      }
    }
  }
}

.site-banner {
  &__copy {
    line-height: 20px;
    @include breakpoint($medium-down) {
      line-height: 15px;
    }
    .site-banner-rotator-items {
      margin-top: 0;
      @include breakpoint($medium-down) {
        height: 30px;
      }
      div {
        @include breakpoint($medium-down) {
          height: 40px;
        }
      }
    }
  }
}

.site-header__menu {
  .site-header__menu-list--desktop {
    .menu--lvl-1 {
      @include breakpoint($landscape-down) {
        font-size: 11px;
        margin-left: 7px;
      }
    }
  }
}
