.order-details-page {
  &__content {
    .order {
      &-details {
        &__payment {
          padding-bottom: 15px;
        }
      }
      &-products {
        .cart-item {
          @include breakpoint($landscape-up) {
            &__qty {
              width: 9%;
              word-wrap: initial;
            }
            &__status {
              width: 11%;
              word-wrap: initial;
            }
          }
        }
      }
    }
  }
}

.past-purchases-page__content {
  .past-purchases {
    .past-purchase {
      &:nth-child(3n + 4) {
        @include breakpoint($landscape-up) {
          clear: left;
        }
      }
      &:nth-child(2n + 3) {
        @include breakpoint($mobile-only) {
          clear: left;
        }
      }
    }
  }
}

#account-page__welcome {
  margin-bottom: 13px;
}

.address-form {
  .form-item {
    &--full {
      input[type='text'],
      input[type='tel'],
      select {
        @include breakpoint($landscape-down) {
          width: 100%;
        }
      }
      select {
        @include breakpoint($landscape-down) {
          margin-bottom: 1.5em;
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  .email_comp {
    width: 100%;
    .email_input {
      width: 60%;
      float: left;
    }
    input[type='email'] {
      width: 100%;
    }
    .input-btn {
      margin: 0 5px;
      top: 0;
    }
    .terms_condition_cont {
      padding: 5px 0;
      clear: left;
    }
  }
  ul {
    &.error_messages {
      color: $red;
    }
  }
}

.new-account {
  #registration_short {
    .new-account__item__show-password {
      display: block;
    }
  }
}
