/* Info popup styles for privacy policy */
.info-link-gdpr {
  cursor: pointer;
  margin-bottom: 10px;
  border: 0px;
  display: block;
  text-decoration: underline;
}

.info-icon-gdpr {
  cursor: pointer;
  background: #392720;
  color: #fff;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: block;
  text-transform: lowercase;
  padding: 1px 0px 0px 8px;
  line-height: 20px;
}

.info-message-txt-gdpr {
  display: none;
}

.info-msg-gdpr {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  background-color: #fff;
  padding: 20px;
  width: 90%;
  border: 1px solid #392720;
  max-width: 500px;
  &__arrow {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: 0px;
    border: 10px solid transparent;
    border-bottom-color: #392720;
    top: -10px;
    margin-top: -10px;
    &.top-right-arrow {
      left: 478px; /* 500px minus Border 2px and Triangle 20px */
    }
  }
  &__txt {
    position: relative;
  }
  &__close {
    position: absolute;
    top: -20px;
    right: -20px;
    font-size: 15px;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

#registration_short {
  .form-item.new-account__item.checkbox.password {
    .picker.picker-checkbox,
    .password-notice {
      float: left;
    }
    .password-notice {
      margin: 10px 0px 0px 10px;
    }
  }
}

.info-for-left,
.info-icon-right {
  float: left;
}

.info-icon-right {
  margin-left: 10px;
}

.welcome-15 {
  .site-email-signup__success-terms {
    width: 100%;
    float: $ldirection;
    margin: auto;
    a[message-in='newsletter-terms-msg'] {
      color: $white;
      line-height: 16px;
    }
  }
}

.section-chatprivacypolicy {
  .site-header {
    &__wrapper {
      display: none;
    }
  }
  .site-footer,
  .offer-block {
    display: none;
  }
  // !important was used because the live chat styles are controlled by LivePerson as inline.
  .LPMcontainer {
    display: none !important;
  }
  .site-content {
    padding-top: 10px !important;
  }
}
