#bt_notification {
  div {
    &.bt-content {
      border: 1px solid $black;
      padding: 0;
      background-color: $white;
      z-index: 99999;
      font-size: 12px;
      line-height: 16px;
      border-color: $black;
      border-width: 10px;
      .bt-close-link {
        display: block;
        width: 15px;
        height: 15px;
        background: url('/media/images/cookies/cookie_close_x.png') 50% 50% no-repeat;
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .button {
        width: 100%;
        &.bt-close-link {
          position: absolute;
          top: 10px;
          right: 10px;
          display: block;
          max-width: 15px;
          height: 15px;
          background: url('/media/images/cookies/cookie_close_x.png') 50% 50% no-repeat;
          text-indent: -9999em;
          min-width: auto;
        }
      }
      .btn-wrap {
        margin-top: 10px;
        .button {
          width: 100%;
        }
      }
      .bt-pp-section {
        background: $color-off-black;
        padding: 10px;
        border: 1px solid $color-off-black;
        color: $white;
        h6 {
          font-size: 16px;
          padding-bottom: 10px;
        }
        .bt-content__link {
          color: $white;
        }
      }
      .bt-cookie-section {
        padding: 10px;
        h6 {
          font-size: 16px;
          padding-bottom: 10px;
        }
        .btn {
          font-size: 15px;
          border: 1px solid $color-off-black;
          padding: 9px 19px 8px 19px;
          text-transform: uppercase;
          text-align: center;
          display: inline-block;
          text-decoration: none;
          width: 100%;
          &:hover,
          &:active {
            cursor: pointer;
            background: $color-off-black;
            color: $white;
          }
        }
      }
    }
    .bt-content {
      &__sub-hdr {
        padding: 0 0 10px 0;
        font-size: medium;
      }
    }
    &.bt-content-mobile {
      .btn-wrap {
        padding: 0 10px 10px 10px;
      }
    }
    &.bt-bottom-right {
      position: fixed;
      bottom: 0;
      width: 100%;
      right: 0;
      border-bottom-width: 0;
      @include breakpoint($medium-up) {
        right: 10px;
        width: 300px;
      }
    }
    &.bt-bottom-full {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom-width: 0;
    }
  }
}

#bt_settings {
  div {
    &.bt-content {
      border: 1px solid $black;
      padding: 15px;
      background-color: $white;
      z-index: 10000;
      font-size: 12px;
      border-color: $color-off-black;
      border-width: 2px;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      .bt-intro {
        h2 {
          text-transform: none;
          color: $color-off-black;
        }
        .bt-intro-copy {
          p {
            margin-top: 1em;
          }
        }
      }
      .slide {
        position: relative;
        overflow: hidden;
        width: 228px;
        margin: 20px auto;
        @include breakpoint($medium-up) {
          width: 525px;
        }
      }
      .slide-toggle {
        position: relative;
        overflow: hidden;
        width: 230px;
        margin: 0;
        background: transparent url('/media/images/cookies/m_all-off_en.png');
        background-size: contain;
        @include breakpoint($medium-up) {
          width: 525px;
          background: transparent url('/media/images/cookies/all-off_en.png') 50% 100% no-repeat;
        }
        li {
          cursor: pointer;
          float: left;
          width: 76px;
          height: 126px;
          list-style-type: none;
          @include breakpoint($medium-up) {
            width: 175px;
            height: 149px;
          }
          span {
            display: none;
            font-weight: bold;
            text-align: center;
            margin: 5px;
          }
        }
      }
      .mask {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 230px;
        border-right: 1px solid $color-footer-background;
        transition: width 1s;
        -webkit-transition: width 1s;
        .slide-toggle {
          background-image: url('/media/images/cookies/m_all-on_en.png');
          @include breakpoint($medium-up) {
            background-image: url('/media/images/cookies/all-on_en.png');
          }
        }
        .drag {
          position: absolute;
          right: 24px;
          bottom: 10px;
          width: 26px;
          height: 11px;
          background: transparent url('/media/images/cookies/icon_arrow.png') 50% 100% no-repeat;
          @include breakpoint($medium-up) {
            bottom: 12px;
            right: 70px;
          }
        }
      }
      .bt-privacy-info {
        display: none;
        overflow: hidden;
        &.selected {
          display: block;
          &[data-userprivacy='OPTED_IN'] {
            .bt-privacy-willnot {
              display: none;
            }
          }
        }
        h3 {
          padding: 1em 1em 1em 40px;
          background-position: 10px 50%;
          background-repeat: no-repeat;
        }
        .bt-privacy {
          &-will {
            h3 {
              background-image: url('/media/images/cookies/icon_safe_lock.png');
            }
          }
          &-willnot {
            h3 {
              background-image: url('/media/images/cookies/icon_unsafe_lock.png');
            }
          }
          &-info {
            div {
              width: 100%;
              float: none;
            }
            h3 {
              background-color: $white;
            }
          }
        }
        .bt-privacy-will,
        .bt-privacy-willnot {
          width: 100%;
          margin-right: 2%;
          float: left;
          @include breakpoint($medium-up) {
            width: 48%;
          }
          ul {
            list-style-type: none;
            padding-left: 0;
            margin: 1em;
            li {
              margin: 0.8em 0;
              padding-left: 30px;
              background-position: 0 50%;
              background-repeat: no-repeat;
            }
          }
          &.bt-privacy-will,
          &.bt-privacy-willnot {
            ul {
              li {
                list-style-type: none;
              }
            }
          }
          &.bt-privacy-will {
            ul {
              li {
                background-image: url('/media/images/cookies/icon_safe_thick.png');
              }
            }
          }
          &.bt-privacy-willnot {
            ul {
              li {
                background-image: url('/media/images/cookies/icon_unsafe_thick.png');
              }
            }
          }
        }
      }
      &-desktop {
        padding: 2em;
        height: 100%;
      }
      &-mobile {
        .slide {
          margin: 20px auto;
          width: 246px;
          &-toggle {
            background-image: url('/media/images/cookies/m_all-off_en.png');
            width: 246px;
            li {
              width: 82px;
              height: 136px;
              span {
                display: none;
              }
            }
          }
        }
        .mask {
          .slide-toggle {
            background-image: url('/media/images/cookies/m_all-on_en.png');
          }
          .drag {
            right: 30px;
            bottom: 11px;
          }
        }
      }
    }
  }
}

#pr-write {
  .p-w-r {
    .pr-header-required {
      display: block;
    }
  }
}
