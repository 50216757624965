.bt-cookie-section {
  h6 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
  }
  .btn-wrap {
    color: $color-off-black;
  }
  a {
    width: 100%;
    font-weight: 400;
    padding: 9px 19px 8px;
    font-size: 15px;
    letter-spacing: normal;
    display: inline-block;
    margin-top: 0;
    text-transform: uppercase;
    color: $color-off-black;
    border: 1px solid $color-off-black;
    text-align: center;
    text-decoration: none;
    &:hover {
      color: $white;
      background-color: $color-off-black;
    }
  }
}

#bt_settings {
  .content {
    .bt-content {
      .bt-privacy-info {
        &.selected {
          &[data-userprivacy='OPTED_IN'] {
            .bt-privacy-willnot {
              display: none;
            }
          }
        }
        li {
          font-size: 14px;
          line-height: 19px;
          list-style-type: none;
        }
        .bt-privacy-will,
        .bt-privacy-willnot {
          width: 100%;
          h3 {
            padding-#{$rdirection}: 10px;
            background-position: 10px 50%;
            background-repeat: no-repeat;
            background-color: $white;
            font-size: 22px;
            letter-spacing: 0;
            font-weight: 300;
            @include breakpoint($medium-up) {
              font-size: 24px;
            }
          }
          @include breakpoint($medium-up) {
            width: 48%;
          }
        }
      }
      .bt-intro {
        h2 {
          font-size: 38px;
          line-height: 40px;
          margin-bottom: 30px;
          color: $color-off-black;
          font-weight: 300;
        }
        .bt-intro-copy {
          font-size: 14px;
          line-height: 19px;
          color: $color-off-black;
        }
      }
      .slide-toggle {
        li {
          list-style-type: none;
          width: 82px;
          height: 148px;
          @include breakpoint($medium-up) {
            width: 170px;
            height: 140px;
          }
        }
        width: 246px;
        background-size: 246px 147px;
        margin: auto;
        background-image: url(/media/export/images/cookies/m_all-off.png);
        @each $region, $regional_lang in $regions {
          html:lang(#{$region}) & {
            background-image: url('/media/export/images/cookies/m_all-off_#{$regional_lang}.png');
          }
        }
        @include breakpoint($medium-up) {
          background-image: url(/media/export/images/cookies/all-off.png);
          width: 525px;
          background-size: contain;
          @each $region, $regional_lang in $regions {
            html:lang(#{$region}) & {
              background-image: url('/media/export/images/cookies/all-off_#{$regional_lang}.png');
            }
          }
        }
        .mask {
          transition: width 1s;
          -webkit-transition: width 1s;
          .slide-toggle {
            background-image: url(/media/export/images/cookies/m_all-on.png);
            @each $region, $regional_lang in $regions {
              html:lang(#{$region}) & {
                background-image: url('/media/export/images/cookies/m_all-on_#{$regional_lang}.png');
              }
            }
            @include breakpoint($medium-up) {
              background-image: url(/media/export/images/cookies/all-on.png);
              @each $region, $regional_lang in $regions {
                html:lang(#{$region}) & {
                  background-image: url('/media/export/images/cookies/all-on_#{$regional_lang}.png');
                }
              }
            }
          }
          .drag {
            #{$rdirection}: 32px;
            @include breakpoint($medium-up) {
              #{$rdirection}: 70px;
            }
          }
        }
      }
      .slide {
        width: 264px;
        margin: 20px auto;
        @include breakpoint($medium-up) {
          width: 525px;
        }
      }
      h3 {
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $black;
      }
    }
  }
}

#bt_notification_container {
  .content {
    .bt-content {
      #{$rdirection}: 0;
      width: 100%;
      transition: all 5s;
      -webkit-transition: all 5s;
      border: 10px solid $color-off-black;
      @include breakpoint($medium-up) {
        width: 294px;
        #{$rdirection}: 10px;
      }
      .bt-pp-section {
        h6 {
          font-size: 14px;
          line-height: 16px;
          font-weight: 700;
          margin-top: 10px;
        }
        a {
          color: $white;
        }
      }
    }
  }
}

.tealium {
  display: none !important;
}
