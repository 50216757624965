.checkout {
  .address_form_container {
    .form-item {
      @include breakpoint($landscape-up) {
        &.address3_container,
        &.address4_container,
        &.address5_container {
          float: left;
          width: 50%;
        }
        &.address5_container,
        &.postal_code_container {
          padding-right: 1.33em;
          padding-bottom: 0;
          clear: both;
        }
        &.country_container {
          padding-right: 0;
          clear: none;
        }
      }
      &.city_container,
      &.address3_container,
      &.address5_container,
      &.address1_container {
        margin-bottom: 1.5em;
      }
    }
  }
  .checkout__content {
    #address-section-container {
      select {
        margin: 0;
      }
    }
  }
}
