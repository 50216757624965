.address-book-page__overlay {
  .address-overlay {
    .address-form {
      .form-item {
        @include breakpoint($landscape-up) {
          &.city_container,
          &.address1_container,
          &.address3_container,
          &.address5_container {
            label {
              display: block;
            }
            select {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
