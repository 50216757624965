@mixin select-box-shared {
  min-height: 35px;
  line-height: 35px;
  white-space: normal;
  max-width: 630px;
  width: 100%;
  &:after {
    top: 4px;
  }
}

.checkout {
  .sign-in-page {
    margin: 0;
    padding: 0;
    @include breakpoint($medium-up) {
      .new-account,
      .return-user {
        width: 48%;
        margin: 0 15px 0 0;
        float: left;
        position: relative;
        min-height: 300px;
        form {
          input[type='submit'] {
            float: left;
            position: absolute;
            top: 243px;
            height: 40px;
            background: 0 0;
            color: $color-off-black;
            border: 1px solid $color-off-black;
            &:hover {
              background: $color-off-black;
              color: $white;
            }
          }
        }
      }
    }
    .return-user {
      @include breakpoint($medium-up) {
        margin: 0;
      }
    }
  }
  .checkout__content {
    padding: 0 10px;
    .checkout-panel__heading {
      font-weight: 700;
      font-size: 18px;
      margin: 15px 0;
      @include breakpoint($medium-up) {
        min-height: 60px;
        font-size: 28px;
        line-height: 30px;
        letter-spacing: -1px;
        font-weight: 400;
      }
    }
    .promotions-container,
    .privacy_policy_container {
      a {
        text-decoration: underline;
      }
    }
    #address-section-container {
      select,
      .select-box {
        max-width: 630px;
        width: 100%;
        margin: 10px 0 0 0;
      }
      .select-box__label,
      .select-box__options {
        @include select-box-shared;
      }
    }
  }
  &__sidebar {
    margin: 0;
    padding: 0 10px;
    float: left;
    @include breakpoint($landscape-up) {
      width: 28.5%;
      float: right;
      padding: 0;
    }
    @include breakpoint($medium-down) {
      width: 100%;
    }
    .offer_code_module {
      border-top: 1px solid $light-gray;
      .offer_code {
        margin-bottom: 10px;
        .left {
          &:first-child {
            float: left;
            margin-right: 2%;
            & ~ .left {
              position: relative;
              bottom: 1px;
              float: left;
              margin-bottom: 10px;
            }
          }
        }
      }
      p {
        clear: both;
      }
    }
    #order-status {
      .checkout-panel__heading {
        font-weight: 400;
        font-size: 27px;
        margin: 0;
      }
    }
  }
  #delivery-options {
    border-bottom: 1px solid $light-gray;
    .ship-method-group {
      margin: 10px 0;
      &-label {
        display: none;
      }
      select,
      .select-box__options {
        max-width: 630px;
        width: 100%;
      }
      .select-box__label {
        @include select-box-shared;
      }
    }
    .field-note {
      margin: 10px 0;
      a {
        &.text_link {
          text-decoration: underline;
          margin-bottom: 10px;
        }
      }
    }
  }
  .address_form_container {
    select,
    input[type='text'],
    input[type='tel'] {
      width: 100%;
    }
    select {
      margin-bottom: 20px;
    }
    .phone_label_container {
      margin-top: 7px;
    }
    .title_container {
      margin-bottom: 10px;
      .select-box {
        @include breakpoint($landscape-up) {
          width: 100%;
        }
      }
    }
    @include breakpoint($landscape-up) {
      .form-item {
        &.company_name_container {
          float: left;
        }
        &.address2_container,
        &.city_container,
        &.country_container,
        &.first_name_container,
        &.phone_1_container,
        &.phone_2_container,
        &.address1_container,
        &.last_name_container,
        &.postal_code_container {
          float: left;
          width: 50%;
        }
        &.address2_container,
        &.city_container,
        &.country_container,
        &.first_name_container,
        &.phone_1_container {
          padding-right: 1.33em;
          padding-bottom: 0;
          clear: both;
        }
        &--full {
          width: 100%;
        }
      }
    }
  }
  .right_align {
    float: right;
    .checkout-buttons {
      margin: 15px 0;
    }
  }
  .newsletter-info__fieldset {
    margin-bottom: 10px;
  }
  .sms-promotions {
    input[type='tel'] {
      width: 100%;
      @include breakpoint($landscape-up) {
        width: 50%;
      }
    }
    .radio {
      label {
        width: 93%;
      }
    }
  }
  ul {
    &.error_messages {
      &.error {
        margin: 10px 0 15px;
        color: $red;
      }
    }
  }
  .checkout-panel {
    &--viewcart {
      .cart-items {
        &__qty {
          form {
            position: static;
          }
        }
        &__item {
          &--desc {
            position: relative;
          }
          &--remove-product {
            a.remove_link {
              bottom: 0;
            }
          }
        }
      }
    }
    &--order-summary {
      .label {
        @include breakpoint($landscape-up) {
          width: 75%;
        }
      }
      .value {
        @include breakpoint($landscape-up) {
          width: 25%;
        }
      }
    }
    &--samples {
      .samples-content {
        &__free-header {
          padding: 15px 0 0;
        }
      }
    }
  }
  &-esalon {
    .esalon__description {
      margin-bottom: 7px;
    }
    .esalon__form-item--checkbox {
      margin-top: 20px;
    }
    .esalon__field-container {
      .form-item {
        width: 100%;
        select,
        .select-box {
          max-width: 630px;
          width: 100%;
          margin: 10px 0 0 0;
        }
        .select-box__label,
        .select-box__options {
          @include select-box-shared;
        }
      }
      a.esalon__salon-finder-link {
        margin: 10px 0 10px 0;
        display: inline-block;
        text-decoration: underline;
      }
      span.label {
        display: block;
        margin: 0 0 20px 0;
      }
    }
  }
}

.salon-search-locator {
  .salon-search-locator__city {
    label {
      float: left;
      width: 100%;
    }
  }
}

#payment,
#review {
  #review-address-info {
    margin-bottom: 40px;
    .trans_detail_item {
      a {
        &.text_link {
          margin: 15px;
          float: right;
          text-decoration: underline;
          @include breakpoint($landscape-up) {
            margin: 0;
          }
        }
      }
    }
    @include breakpoint($landscape-up) {
      .trans_detail_item {
        position: relative;
        width: 31%;
        margin: 0 2% 60px 0;
        float: left;
        word-wrap: break-word;
      }
      .checkout-panel__heading {
        font-size: 14px;
        color: $gray;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 20px;
        margin: 0 0 5px 0;
      }
    }
  }
  #cart-items {
    thead {
      border-bottom: 1px solid $light-gray;
    }
    th {
      color: $gray;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      padding-bottom: 9px;
      &.product,
      &.prod-info {
        width: 25%;
      }
      &.prod-price {
        width: 18%;
      }
      &.prod-qty {
        width: 13%;
      }
      &.total {
        width: 19%;
        padding-left: 1%;
      }
    }
    td {
      padding-top: 30px;
      &.thumb {
        width: 15%;
        img {
          width: 72%;
          height: auto;
        }
      }
      &.name {
        padding-right: 1%;
        width: 35%;
        padding-bottom: 30px;
        position: relative;
        height: 152px;
        a {
          font-weight: 700;
          text-transform: uppercase;
          &.remove_link {
            text-decoration: underline;
            font-weight: 400;
            letter-spacing: 0.05em;
            bottom: 10px;
            position: absolute;
          }
        }
      }
      &.price {
        width: 18%;
        padding-left: 0;
      }
      &.qty {
        width: 13%;
        padding-left: 0;
        @include breakpoint($medium-up) {
          padding-right: 42px;
          .select-box__label {
            min-width: 64px;
            .select-box__options {
              min-width: 64px;
            }
          }
        }
      }
      &.total {
        width: 19%;
        @include breakpoint($medium-up) {
          text-align: right;
        }
      }
    }
    tr {
      &.cart-item-row-border {
        border-bottom: 1px solid $light-gray;
      }
      &.subtotal-row {
        td {
          &.subtotal {
            text-align: right;
            padding-bottom: 60px;
            padding-top: 10px;
            .total_value {
              float: right;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
  .checkout-buttons-container {
    .checkout-buttons {
      float: right;
      margin: 15px 0;
    }
  }
}

.viewcart-paypal-button {
  .paypal-button {
    float: $ldirection;
    margin-#{$ldirection}: 70px;
  }
  .terms-conditions {
    float: $ldirection;
    margin-top: 10px;
    input {
      float: $ldirection;
      margin-#{$ldirection}: 200px;
    }
    &-txt {
      width: 45%;
      margin-#{$ldirection}: 158px;
    }
    .error {
      color: $red;
    }
  }
}

@media only screen and (min-device-width: $medium-up) and (max-device-width: $max-width) {
  #shipping,
  #mySalon {
    #colorbox {
      #cboxWrapper {
        #cboxContent {
          #cboxClose {
            #{$ldirection}: unset;
          }
        }
      }
    }
  }
}

.viewcart {
  .payments-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    margin: 10px auto 15px;
    .logo {
      background-position: center;
      margin: 0 7px;
      max-width: 40px;
      width: 100%;
      height: 27px;
    }
  }
}
