#become_an_aveda_salon {
  .container {
    margin: 100px auto 0;
    width: 980px;
    @include breakpoint($mobile-only) {
      width: 100%;
      padding: 0 1em;
    }
    .content-header {
      @include breakpoint($landscape-up) {
        width: 100%;
        overflow: hidden;
      }
      border-bottom: 1px solid $light-gray;
      padding-bottom: 6px;
      margin-bottom: 6px;
      @include breakpoint($mobile-only) {
        padding-bottom: 1em;
        margin-bottom: 1.5em;
      }
      @include breakpoint($landscape-up) {
        ul.breadcrumb {
          font-size: 12px;
          margin-top: 23px;
          color: $dark-brown;
          float: left;
          li {
            float: left;
          }
        }
        ul.support {
          float: right;
          margin-top: 20px;
          li {
            cursor: pointer;
            margin-left: 7px;
            color: $dark-brown;
            float: left;
            &.phone {
              background: url(/images/global/header/phone_icon.png) no-repeat scroll 6px -1px transparent;
              padding-bottom: 10px;
            }
            &.email {
              background: url(/images/global/header/email_icon2.png) no-repeat scroll 5px 0 transparent;
              padding-bottom: 10px;
            }
          }
        }
      }
      @include breakpoint($mobile-only) {
        .breadcrumb,
        .support {
          display: inline-block;
          font-size: 12px;
        }
        .support {
          float: right;
        }
      }
    }
    .content-body {
      #form--errors--salon_partners {
        li {
          color: $red;
        }
      }
      @include breakpoint($mobile-only) {
        h1 {
          text-transform: uppercase;
          border-bottom: 1px solid;
          margin-bottom: 1em;
          font-size: 1.8em;
        }
        h3 {
          display: none;
        }
      }
    }
    .fieldset {
      @include breakpoint($landscape-up) {
        float: left;
        width: 50%;
        overflow: hidden;
      }
      margin-bottom: 10px;
      .field-label {
        @include breakpoint($landscape-up) {
          float: left;
          width: 141px;
          margin-right: 20px;
          font-size: 12px;
        }
        color: $dark-brown;
      }
      .field-input {
        @include breakpoint($landscape-up) {
          margin-left: 152px;
          overflow: hidden;
        }
        input {
          background: transparent;
          width: 273px;
          @include breakpoint($mobile-only) {
            width: 100%;
          }
        }
        select {
          background: transparent;
          @include breakpoint($landscape-up) {
            width: 273px;
            display: block;
          }
          @include breakpoint($mobile-only) {
            width: 100%;
          }
        }
        .select-box {
          @include breakpoint($landscape-up) {
            display: none;
          }
          width: 70%;
          &__options {
            position: relative;
            margin-top: -7px;
            z-index: 0;
            width: 70%;
            top: 0;
          }
          &__options.open {
            display: inline-block;
          }
          &__label {
            width: 70%;
          }
        }
      }
      .field-extra {
        float: left;
        &.first {
          padding-top: 0;
        }
        &.contact-name {
          display: none;
        }
        input {
          clear: left;
          float: left;
          width: auto;
        }
        label {
          float: left;
          width: auto;
          margin-left: 5px;
        }
      }
      textarea {
        width: 276px;
        @include breakpoint($mobile-only) {
          width: 100%;
        }
        background: transparent;
        border: 1px solid $light-gray;
      }
      &.right {
        float: right;
      }
      &.clear-left {
        clear: left;
      }
      &.submit {
        padding-bottom: 5px;
      }
    }
  }
  table {
    margin: 0 auto;
  }
}
